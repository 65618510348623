import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AdminGuard({ children }) {
  const account = useSelector((state) => state.account);

  // console.log(account.user)
  if (account.user && account.user.role_id !== 1) {
    return <Redirect to="/403" />;
  }

  return children;
}

AdminGuard.propTypes = {
  children: PropTypes.any
};

export default AdminGuard;
